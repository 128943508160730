import React from "react";
import useSound from 'use-sound';
const honk = require("../assets/honk.mp3");

// import PunchyHistory from "./punchyhistory";

function PunchCard(props: any) {
	const refCanvas = React.useRef(null);
	const refImages = React.useRef([]);
	const refDone = React.useRef(null);
	const { punchyLayer,basepunchy, setPunchyLayer,completedImageList, setCompletedImageList } = props;
	const [playHonk] = useSound(honk);

	function clearImage(){
		playHonk()
		setPunchyLayer([basepunchy]);
	}
	function downloadImage() {
		let canvas = refCanvas.current;
		// let finalthumb = refDone.current;
		// finalthumb.src = canvas
		//   .toDataURL("image/png")
		//   .replace("image/png", "image/octet-stream");
		var link = document.createElement("a");
		link.setAttribute("download", "Punchy.png");
		link.setAttribute(
			"href",
			canvas.toDataURL("image/png").replace("image/png", "image/octet-stream")
		);
		link.click();
	}
	function compareLayers(a: any, b: any) {
		if (a.zIndex < b.zIndex) {
			return -1;
		}
		if (a.zIndex > b.zIndex) {
			return 1;
		}
		return 0;
	}

	async function drawImage(ctx: any, url: any) {
		const image = new Image();
		image.src = url;
		await image.decode();
		ctx.drawImage(image, 0, 0);

		// image.onload = () => {
		//  ctx.drawImage(image, 0, 0);
		// };
	}
 
	function saveImageToCanvas() {
		let canvas = refCanvas.current;
		let ctx = canvas.getContext("2d");
		ctx.clearRect(0, 0, canvas.width, canvas.height);
		if (punchyLayer) {
			const sortedLayers = punchyLayer.sort(compareLayers);
			sortedLayers.map((img: any) => {
				return drawImage(ctx, img.src);
			});

			//alert("waiting");
			setTimeout(() => {
				downloadImage()
			}, 1000);
		}
	}

	if (!punchyLayer) {
		return <React.Fragment />
	}
	return (
		<>
			<div className="border border-2 border-dark punchy-card d-flex flex-column h-100">
				{/* <button className="btn btn-primary" onClick={downloadImage}>
					Download
				</button> */}
				<div className="punchy-container">
					{punchyLayer.map((image: any, idx: number) => {
						return (
							<img
								key={`img-layer-${idx}`}
								ref={refImages[idx]}
								id={"image" + image.id}
								className="punchy-image"
								src={image.src}
								alt={image.alt}
								style={{ zIndex: image.zIndex }}
							/>
						);
					})}
				</div>

				<div className="d-flex border-top border-2 border-dark">
					<button className="btn btn-outline-primary border-transparent" onClick={clearImage}>
						Clear All
					</button>
					<div className="border-start border-2 border-dark ms-auto">
						<button className="btn btn-primary text-white" onClick={saveImageToCanvas}>
							Download Punchy
						</button>
					</div>
				</div>
			</div>

			<canvas
				className="punchy-canvas"
				ref={refCanvas}
				id="myCanvas"
				width="1000"
				height="1000"
			></canvas>
			{/* <div className="col-sm-6">
				<PunchyHistory {...props} />
			</div> */}
		</>
	);
}

export default PunchCard;
