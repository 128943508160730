import React, { FC } from 'react'
import ImageList from './imagelist'
import classnames from 'classnames';
import {ReactComponent as IconBackground} from '../assets/images/icon-background.svg';
import {ReactComponent as IconClothes} from '../assets/images/icon-clothes.svg';
import {ReactComponent as IconEyes} from '../assets/images/icon-eyes.svg';
import {ReactComponent as IconFeet} from '../assets/images/icon-feet.svg';
import {ReactComponent as IconFlair} from '../assets/images/icon-flair.svg';
import {ReactComponent as IconHands} from '../assets/images/icon-hands.svg';
import {ReactComponent as IconHead} from '../assets/images/icon-head.svg';
import {ReactComponent as IconMouth} from '../assets/images/icon-mouth.svg';


export type TabIds = {
	head: string,
	eyes: string,
	mouth: string,
	clothes: string,
	arms: string,
	feet: string,
	flair: string,
	background: string,
}

interface ITabs {
	id: keyof TabIds;
	label: string;
	icon: FC;
}

export const Tabs = (props: any) => {
	const tabs: ITabs[] = [
		{
			id: 'head',
			label: 'Head',
			icon: IconHead,
		},
		{
			id: 'eyes',
			label: 'Eyes',
			icon: IconEyes,
		},
		{
			id: 'mouth',
			label: 'Mouth',
			icon: IconMouth,
		},
		{
			id: 'clothes',
			label: 'Clothes',
			icon: IconClothes,
		},
		{
			id: 'arms',
			label: 'Arms',
			icon: IconHands,
		},
		{
			id: 'feet',
			label: 'Feet',
			icon: IconFeet,
		},
		{
			id: 'flair',
			label: 'Flair',
			icon: IconFlair,
		},
		{
			id: 'background',
			label: 'Background',
			icon: IconBackground,
		},
	];
  
  const [activeTab, setActiveTab] = React.useState<string>(tabs[0].id);
  
	return (
		<div className="customize-options d-flex flex-column h-100 border border-dark border-2">
			<h3 className="customize-title mb-0 px-3 py-2">Customize Options</h3>
			<div className="tab-container overflow-hidden flex-grow-1">
				<div className="tab overflow-auto">
					<div className="font-monospace text-center px-3 py-1 text-truncate flex-shrink-0"><small>Categories</small></div>
					{tabs.map((tab, index) =>
						<button
							key={`button-${index}`}
							onClick={() => setActiveTab(tab.id)}
							className={classnames("tablinks text-truncate flex-shrink-0 d-flex flex-column align-items-center", activeTab === tab.id && "active")}
						>
							<tab.icon />
							<small className={classnames(activeTab === tab.id && "fw-bold")}>{tab.label}</small>
						</button>
					)}
				</div>

				<div className="outlet pt-4 pb-3 h-100 overflow-auto flex-grow-1 flex-shrink-1 container-fluid">
					{/* content will be shown here */}
					<ImageList {...props} tabname={activeTab} />
				</div>
			</div>
		</div>
	);
}
export default Tabs;
