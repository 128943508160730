// new assets

import { TabIds } from './tabs';

const img = [
  {
    type: 'arms',
    thumb: require('../images/tn-arms-barbell.png'),
    src: require('../images/punchy-arms-barbell.png')
  },
  {
    type: 'arms',
    thumb: require('../images/tn-arms-cube.png'),
    src: require('../images/punchy-arms-cube.png')
  },
  {
    type: 'arms',
    thumb: require('../images/tn-arms-diamond-stick.png'),
    src: require('../images/punchy-arms-diamond-stick.png')
  },
  {
    type: 'arms',
    thumb: require('../images/tn-arms-flashlight.png'),
    src: require('../images/punchy-arms-flashlight.png')
  },
  {
    type: 'arms',
    thumb: require('../images/tn-arms-gamer.png'),
    src: require('../images/punchy-arms-gamer.png')
  },
  {
    type: 'arms',
    thumb: require('../images/tn-arms-kendama.png'),
    src: require('../images/punchy-arms-kendama.png')
  },
  {
    type: 'arms',
    thumb: require('../images/tn-arms-knight.png'),
    src: require('../images/punchy-arms-knight.png')
  },
  {
    type: 'arms',
    thumb: require('../images/tn-arms-martini.png'),
    src: require('../images/punchy-arms-martini.png')
  },
  {
    type: 'arms',
    thumb: require('../images/tn-arms-painter.png'),
    src: require('../images/punchy-arms-painter.png')
  },
  {
    type: 'arms',
    thumb: require('../images/tn-arms-projectpunchy.png'),
    src: require('../images/punchy-arms-projectpunchy.png')
  },
  {
    type: 'arms',
    thumb: require('../images/tn-arms-punchypunchy.png'),
    src: require('../images/punchy-arms-punchypunchy.png')
  },
  {
    type: 'arms',
    thumb: require('../images/tn-arms-spider.png'),
    src: require('../images/punchy-arms-spider.png')
  },
  {
    type: 'arms',
    thumb: require('../images/tn-arms-strong.png'),
    src: require('../images/punchy-arms-strong.png')
  },
  {
    type: 'arms',
    thumb: require('../images/tn-arms-teatime.png'),
    src: require('../images/punchy-arms-teatime.png')
  },
  {
    type: 'arms',
    thumb: require('../images/tn-arms-winner.png'),
    src: require('../images/punchy-arms-winner.png')
  },
  {
    type: 'background',
    thumb: require('../images/tn-background-beach.png'),
    src: require('../images/punchy-background-beach.png')
  },
  {
    type: 'background',
    thumb: require('../images/tn-background-sky.png'),
    src: require('../images/punchy-background-sky.png')
  },
  {
    type: 'background',
    thumb: require('../images/tn-background-unicorn.png'),
    src: require('../images/punchy-background-unicorn.png')
  },
  {
    type: 'clothes',
    thumb: require('../images/tn-clothing-apron.png'),
    src: require('../images/punchy-clothing-apron.png')
  },
  {
    type: 'clothes',
    thumb: require('../images/tn-clothing-booty.png'),
    src: require('../images/punchy-clothing-booty.png')
  },
  {
    type: 'clothes',
    thumb: require('../images/tn-clothing-bowtie.png'),
    src: require('../images/punchy-clothing-bowtie.png')
  },
  {
    type: 'clothes',
    thumb: require('../images/tn-clothing-hoodie.png'),
    src: require('../images/punchy-clothing-hoodie.png')
  },
  {
    type: 'clothes',
    thumb: require('../images/tn-clothing-sweater.png'),
    src: require('../images/punchy-clothing-sweater.png')
  },
  {
    type: 'eyes',
    thumb: require('../images/tn-eyes-alien.png'),
    src: require('../images/punchy-eyes-alien.png')
  },
  {
    type: 'eyes',
    thumb: require('../images/tn-eyes-anime.png'),
    src: require('../images/punchy-eyes-anime.png')
  },
  {
    type: 'eyes',
    thumb: require('../images/tn-eyes-beach-glasses.png'),
    src: require('../images/punchy-eyes-beach-glasses.png')
  },
  {
    type: 'eyes',
    thumb: require('../images/tn-eyes-darkglasses.png'),
    src: require('../images/punchy-eyes-darkglasses.png')
  },
  {
    type: 'eyes',
    thumb: require('../images/tn-eyes-knight.png'),
    src: require('../images/punchy-eyes-knight.png')
  },
  {
    type: 'eyes',
    thumb: require('../images/tn-eyes-lashes.png'),
    src: require('../images/punchy-eyes-lashes.png')
  },
  {
    type: 'eyes',
    thumb: require('../images/tn-eyes-monocle.png'),
    src: require('../images/punchy-eyes-monocle.png')
  },
  {
    type: 'eyes',
    thumb: require('../images/tn-eyes-ninja.png'),
    src: require('../images/punchy-eyes-ninja.png')
  },
  {
    type: 'eyes',
    thumb: require('../images/tn-eyes-shock.png'),
    src: require('../images/punchy-eyes-shock.png')
  },
  {
    type: 'eyes',
    thumb: require('../images/tn-eyes-spider.png'),
    src: require('../images/punchy-eyes-spider.png')
  },
  {
    type: 'eyes',
    thumb: require('../images/tn-eyes-squinty.png'),
    src: require('../images/punchy-eyes-squinty.png')
  },
  {
    type: 'eyes',
    thumb: require('../images/tn-eyes-wink.png'),
    src: require('../images/punchy-eyes-wink.png')
  },
  {
    type: 'feet',
    thumb: require('../images/tn-feet-airforce1.png'),
    src: require('../images/punchy-feet-airforce1.png')
  },
  {
    type: 'feet',
    thumb: require('../images/tn-feet-ballerina.png'),
    src: require('../images/punchy-feet-ballerina.png')
  },
  {
    type: 'feet',
    thumb: require('../images/tn-feet-basic.png'),
    src: require('../images/punchy-feet-basic.png')
  },
  {
    type: 'feet',
    thumb: require('../images/tn-feet-converse.png'),
    src: require('../images/punchy-feet-converse.png')
  },
  {
    type: 'feet',
    thumb: require('../images/tn-feet-high-heels.png'),
    src: require('../images/punchy-feet-high-heels.png')
  },
  {
    type: 'feet',
    thumb: require('../images/tn-feet-knight.png'),
    src: require('../images/punchy-feet-knight.png')
  },
  {
    type: 'feet',
    thumb: require('../images/tn-feet-punchycorn.png'),
    src: require('../images/punchy-feet-punchycorn.png')
  },
  {
    type: 'feet',
    thumb: require('../images/tn-feet-punchypunchy.png'),
    src: require('../images/punchy-feet-punchypunchy.png')
  },
  {
    type: 'flair',
    thumb: require('../images/tn-flair-cat.png'),
    src: require('../images/punchy-flair-cat.png')
  },
  {
    type: 'flair',
    thumb: require('../images/tn-flair-corgi.png'),
    src: require('../images/punchy-flair-corgi.png')
  },
  {
    type: 'flair',
    thumb: require('../images/tn-flair-fish.png'),
    src: require('../images/punchy-flair-fish.png')
  },
  {
    type: 'flair',
    thumb: require('../images/tn-flair-ghost.png'),
    src: require('../images/punchy-flair-ghost.png')
  },
  {
    type: 'flair',
    thumb: require('../images/tn-flair-painting.png'),
    src: require('../images/punchy-flair-painting.png')
  },
  {
    type: 'flair',
    thumb: require('../images/tn-flair-pig.png'),
    src: require('../images/punchy-flair-pig.png')
  },
  {
    type: 'flair',
    thumb: require('../images/tn-flair-raining-money.png'),
    src: require('../images/punchy-flair-raining-money.png')
  },
  {
    type: 'flair',
    thumb: require('../images/tn-flair-umbrella.png'),
    src: require('../images/punchy-flair-umbrella.png')
  },
  {
    type: 'head',
    thumb: require('../images/tn-hair-front-bangs.png'),
    src: require('../images/punchy-hair-front-bangs.png')
  },
  {
    type: 'head',
    thumb: require('../images/tn-hair-guy-fieri.png'),
    src: require('../images/punchy-hair-guy-fieri.png')
  },
  {
    type: 'head',
    thumb: require('../images/tn-hair-pigtails.png'),
    src: require('../images/punchy-hair-pigtails.png')
  },
  {
    type: 'head',
    thumb: require('../images/tn-head-ballerina.png'),
    src: require('../images/punchy-head-ballerina.png')
  },
  {
    type: 'head',
    thumb: require('../images/tn-head-batman.png'),
    src: require('../images/punchy-head-batman.png')
  },
  {
    type: 'head',
    thumb: require('../images/tn-head-cowboy.png'),
    src: require('../images/punchy-head-cowboy.png')
  },
  {
    type: 'head',
    thumb: require('../images/tn-head-knight.png'),
    src: require('../images/punchy-head-knight.png')
  },
  {
    type: 'head',
    thumb: require('../images/tn-head-punchcard-cap.png'),
    src: require('../images/punchy-head-punchcard-cap.png')
  },
  {
    type: 'head',
    thumb: require('../images/tn-head-sailor-moon.png'),
    src: require('../images/punchy-head-sailor-moon.png')
  },
  {
    type: 'head',
    thumb: require('../images/tn-head-tophat.png'),
    src: require('../images/punchy-head-tophat.png')
  },
  {
    type: 'head',
    thumb: require('../images/tn-head-toque-brown.png'),
    src: require('../images/punchy-head-toque-brown.png')
  },
  {
    type: 'head',
    thumb: require('../images/tn-head-unicorn.png'),
    src: require('../images/punchy-head-unicorn.png')
  },
  {
    type: 'mouth',
    thumb: require('../images/tn-mouth-big-mouth.png'),
    src: require('../images/punchy-mouth-big-mouth.png')
  },
  {
    type: 'mouth',
    thumb: require('../images/tn-mouth-determined.png'),
    src: require('../images/punchy-mouth-determined.png')
  },
  {
    type: 'mouth',
    thumb: require('../images/tn-mouth-hamster.png'),
    src: require('../images/punchy-mouth-hamster.png')
  },
  {
    type: 'mouth',
    thumb: require('../images/tn-mouth-lips.png'),
    src: require('../images/punchy-mouth-lips.png')
  },
  {
    type: 'mouth',
    thumb: require('../images/tn-mouth-moustache.png'),
    src: require('../images/punchy-mouth-moustache.png')
  },
  {
    type: 'mouth',
    thumb: require('../images/tn-mouth-shock.png'),
    src: require('../images/punchy-mouth-shock.png')
  },
  {
    type: 'mouth',
    thumb: require('../images/tn-mouth-spider.png'),
    src: require('../images/punchy-mouth-spider.png')
  },
  {
    type: 'mouth',
    thumb: require('../images/tn-mouth-tongue.png'),
    src: require('../images/punchy-mouth-tongue.png')
  },
  {
    type: 'mouth',
    thumb: require('../images/tn-mouth-weak-smile.png'),
    src: require('../images/punchy-mouth-weak-smile.png')
  },
  {
    type: 'mouth',
    thumb: require('../images/tn-mouth-winner.png'),
    src: require('../images/punchy-mouth-winner.png')
  }
]



export interface IImages {
  src: string;
  thumb: string;
  type: keyof TabIds;
  zIndex: number;
  selected: boolean;
}


const zIndexByType = [
  {
    type: 'mouth',
    zIndex: 3
  },
  {
    type: 'eyes',
    zIndex: 4
  },
  {
    type: 'arms',
    zIndex: 7
  },
  {
    type: 'head',
    zIndex: 6
  },
  {
    type: 'clothes',
    zIndex: 5
  },
  {
    type: 'flair',
    zIndex: 8
  },
  {
    type: 'feet',
    zIndex: 2
  },
  {
    type: 'background',
    zIndex: 0
  },
] as {
  type: keyof TabIds;
  zIndex: number;
}[]

const ImmageCollection: IImages[] = []

img.forEach(src => {
  ImmageCollection.push({
    thumb: src.thumb,
    src: src.src,
    zIndex: zIndexByType.find(v => v.type === src.type).zIndex,
    selected: false,
    type: src.type as keyof TabIds
  })});

export default JSON.stringify(ImmageCollection);
