import * as React from "react";
import './index.scss';
import Tabs from "./Components/tabs";
// import FileManagement from "./Components/filemanagement";
import punchybase from "./images/Punchy-Blank.png";
import PunchCard from './Components/punchycard';
import {ReactComponent as LogoPunchcard} from './assets/images/logo-punchcard.svg';

function App() {
  const basepunchy = {
    id: 0,
    src: punchybase,
    type: "base",
    altname: "Punchy",
    zIndex: 1,
  };

  const [punchyLayer, setPunchyLayer] = React.useState([basepunchy]);
  const [completedImageList, setCompletedImageList] = React.useState([]);
  const props = {
    punchyLayer,
    setPunchyLayer,
    basepunchy,
    completedImageList,
    setCompletedImageList,
  };
  return (
    <div className="container py-5 vh-100 overflow-hidden d-flex flex-column">
      <div className="d-flex justify-content-stretch mb-4">
        <h1 className="bg-dark text-white mb-0 py-3 px-4 h3 flex-grow-1 d-flex align-items-center">Punchy Dress-Up</h1>
        <div className="border border-2 border-dark px-3 py-2">
          <LogoPunchcard />
        </div>
      </div>
      <div className="row h-100 overflow-hidden">
        <div className="col-sm-6 h-100">
          <PunchCard {...props} />
        </div>
        <div className="col-sm-6 h-100">
          <Tabs {...props} />
        </div>
        {/* <div className="col-sm-4">
          <FileManagement {...props} />
        </div> */}
      </div>
    </div>
  );
}

export default App;
