import React from "react";
import jsonImageList, { IImages } from "./imagejson";
import noneImage from '../images/Punchy-None.png'
import { ReactComponent as IconCheck } from '../assets/images/ic_check_48px.svg';
import useSound from 'use-sound';

// I am so sorry, Darcy 😅
const pop = require("../assets/pop.wav");
const swoosh = require("../assets/swoosh.wav");

const ImageList = (props: any) => {
  const { punchyLayer, setPunchyLayer, tabname } = props;
  const [images, setImages] = React.useState([]);
  // const tabname='tabface';

  const [playSwoosh] = useSound(swoosh, {volume: 0.5});
  const [playPop] = useSound(pop);

  React.useEffect(() => {
    const parsedImageList = JSON.parse(jsonImageList);
    const filteredImages = parsedImageList.filter(
      (img: any) => img.type === tabname
    );
    setImages(filteredImages);
  }, [tabname]);
  function isSelected(image: any){ // calculate if the image exists in the punchylayer
      const selecteditems=punchyLayer.filter((img: any)=>{
        return img === image
      })
      return selecteditems.length===1
  }
 
  function onImageClick(image: any) {
   // flagImageAsSelected(image);
    const newlist = punchyLayer.filter((img)=>{ // remove any other layers of the same type before adding another layer
      return img.type!==image.type
    })
    playPop();
    setPunchyLayer([...newlist,image]);
  }
  function clearImageType(typename){
    const newlist = punchyLayer.filter((img)=>{ // remove all other layers of the same type
      return img.type!==typename
    })
    playSwoosh();
    setPunchyLayer([...newlist]);
  }
  return (
    <div className="row mx-n2">
      <div key={-1} className="col-md-6 col-xl-4 mb-3 px-2">
            <img
              onClick={() => clearImageType(tabname)}
              className="itemIcon"
              src={noneImage} alt="none"
            />
          </div>          
      {images.map((image: IImages, index: number) => {
        const isItemSelected = isSelected(image);
        return (
          <div key={`image-${index}`} className="col-md-6 col-xl-4 mb-3 px-2 position-relative">
            {isItemSelected && (
              <div className="item-selected-icon">
                <IconCheck className="text-primary icon" />
              </div>
            )}
            <img
              onClick={() => onImageClick(image)}
              className={isItemSelected ? "itemIcon-selected" : "itemIcon"}
              src={image.thumb}
              alt="img"
            />
          </div>
        );
      })}
    </div>
  );
};

export default ImageList;
